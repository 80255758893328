import React, { useContext } from "react";
import styled from "styled-components";
import CallsContext from "./contexts/CallsContext";
import CommonAxios from "./apis/CommonAxios";
import { DropdownButton, MenuItem } from "react-bootstrap";

const ActionButtonAreaWrapper = styled.div`
  .dropdown {
    button {
      font-weight: bold;
      font-size: 14px;
      box-shadow: 1px 3px 10px 0px #f36a8670;
      line-height: 40px;
      height: 40px;
      text-align: center;
      padding: 0 20px;
      background-color: #f36a86;
      border-color: #f04769;
      color: #ffffff !important;
      border-radius: 5px;
    }
  }
  .dropdown-menu {
    li a {
      padding: 1rem;
    }
  }
`;

const ActionButtonArea = () => {
  const {
    state,
    countdownTimer,
    setCountdownTimer,
    setState,
    paths,
    requestNumber,
    pauseDialer,
    callSequenceChannel,
  } = useContext(CallsContext);
  const { paused, status, twilioDevice } = state;

  const continueCampaign = (e) => {
    console.log("[continueCampaign] Continuing campaign:", state);
    CommonAxios.patch(paths.pauseContinueCampaignUrl)
      .then((response) => {
        console.log("response =>", response);
        if (response.status === 202) {
          console.log("Successfully continued campaign");
          toastr.success("Successfully continued campaign");
          setState({
            status: "ongoing",
            paused: false,
          });
          requestNumber();
        } else {
          toastr.error(
            "There was an error unpausing the campaign, please try again"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        toastr.error(JSON.stringify(err));
      });
  };

  const pauseCampaign = (e) => {
    console.log("Pausing campaign");
    CommonAxios.patch(paths.pauseContinueCampaignUrl)
      .then((response) => {
        console.log("response =>", response);
        if (response.status === 202) {
          toastr.success("Successfully paused campaign");
          clearInterval(state.countdownTimerInterval);
          pauseDialer();
        } else {
          toastr.error(
            "There was an error pausing the campaign, please try again"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        toastr.error(JSON.stringify(err));
      });
  };

  const forceCompleteCampaign = (e) => {
    console.log("Forcing campaign to complete");

    CommonAxios.patch(paths.forceCompleteCampaignUrl)
      .then((response) => {
        console.log("response =>", response);
        if (response.status === 202) {
          console.log("Successfully updated campaign to completed");
          toastr.success("Successfully force completed campaign");
          setState({ status: "completed" });
        } else {
          toastr.error(
            "There was an error completing the campaign, please try again"
          );
        }
      })
      .catch((err) => {
        toastr.error(JSON.stringify(err));
      });
  };

  const pauseForMe = (e) => {
    e.preventDefault();
    if (state.onPhone) {
      toastr.error("You can't do this as you're currently in a call");
    } else {
      pauseDialer();
    }
  };

  const continueForMe = (e) => {
    e.preventDefault();

    toastr.success("Successfully continued for you");
    setState({
      status: "ongoing",
      paused: false,
    });
    requestNumber();
  };

  const nextCall = (e) => {
    e.preventDefault();
    setCountdownTimer(0);
    twilioDevice.disconnectAll();
    requestNumber();
  };

  return (
    <ActionButtonAreaWrapper>
      <DropdownButton
        bsSize="large"
        title="More Actions"
        id="dropdown-size-large"
        style={{ marginRight: "1rem" }}
      >
        {status === "paused" ? (
          <MenuItem onClick={continueCampaign} data-turbolinks="false">
            Continue Campaign
          </MenuItem>
        ) : (
          <>
            <MenuItem onClick={nextCall} data-turbolinks="false">
              Next Call
            </MenuItem>
            <MenuItem onClick={pauseCampaign} data-turbolinks="false">
              Pause Campaign
            </MenuItem>
          </>
        )}
        <MenuItem
          onClick={forceCompleteCampaign}
          data-confirm="Are you sure you want to force complete the campaign? You can't undo this"
        >
          Force Complete
        </MenuItem>
      </DropdownButton>

      {paused ? (
        <a
          className="btn btn-primary-green large"
          href="#"
          onClick={(e) => continueForMe(e)}
        >
          Let's Continue
        </a>
      ) : (
        <a
          className="btn btn-danger large"
          href="#"
          onClick={(e) => pauseForMe(e)}
        >
          Pause For Me
        </a>
      )}
    </ActionButtonAreaWrapper>
  );
};

export default ActionButtonArea;
