import React from "react";

const DialerInfo = (props) => {
  const { createdAt, progress, status } = props;

  const capitalizeFirstLetter = (string) => {
    if (string !== undefined && string !== null && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return "-"
    }
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Created At</th>
          <th>Progress</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{createdAt}</td>
          <td>{progress}</td>
          <td>{capitalizeFirstLetter(status)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default DialerInfo;
