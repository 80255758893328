import React from "react";
import styled from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const CommentsHistoryWrapper = styled.div`
  max-height: 120px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    width: 0px !important; /* Remove scrollbar space */
    background: transparent !important; /* Optional: just make scrollbar invisible */
  }

  .comments-list {
    .item-enter {
      opacity: 0;
    }
    .item-enter-active {
      opacity: 1;
      transition: opacity 500ms ease-in;
    }
    .item-exit {
      opacity: 1;
    }
    .item-exit-active {
      opacity: 0;
      transition: opacity 500ms ease-in;
    }
  }
`;

const CommentBox = styled.div`
  background: #ffec64;
  padding: 3px 8px 5px 8px;
  margin-bottom: 5px;
  border-radius: 4px;
  display: flex;

  p {
    display: flex;
    flex-grow: 1;
    white-space: pre-line;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`;

const Timestamp = styled.div`
  white-space: nowrap;
  padding-left: 1em;
  text-align: right;
  font-size: 10px;
  color: #776900;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 2px;
`;

const CommentsHistory = (props) => {
  return (
    <CommentsHistoryWrapper>
      <TransitionGroup className="comments-list">
        {props.comments.map((comment) => (
          <CSSTransition key={comment.id} timeout={500} classNames="item">
            <CommentBox key={comment.id}>
              <p>{comment.content}</p>
              <Timestamp>{comment.created_at}</Timestamp>
            </CommentBox>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </CommentsHistoryWrapper>
  );
};

export default CommentsHistory;
