import React from "react";
import styled from "styled-components";

const CallWrapperHeader = styled.div`
  text-transform: capitalize;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 1em;
`;

export default CallWrapperHeader;
