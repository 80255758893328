import React, { useContext, useState } from "react";
import styled from "styled-components";
import CallsContext from "./contexts/CallsContext";
import CallWrapperHeader from "./shared/CallWrapperHeader";
import CallWrapper from "./shared/CallWrapper";

const CallQueue = (props) => {
  const { state } = useContext(CallsContext);
  const [showContent, toggleShowContent] = useState(false);

  const displayBody = showContent ? (
    state.numberQueue.length == 0 ? (
      "No Numbers in Queue"
    ) : (
      <CallBody>Some numbers...</CallBody>
    )
  ) : (
    ""
  );

  return (
    <CallWrapper>
      <CallWrapperHeader onClick={() => toggleShowContent(!showContent)}>
        Call Queue ({state.numberQueue.length})
      </CallWrapperHeader>
      {displayBody}
    </CallWrapper>
  );
};

export default CallQueue;
