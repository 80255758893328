import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import CommonAxios from "./apis/CommonAxios";
import { useForm } from "react-hook-form";
import CallsContext from "./contexts/CallsContext";
import CallWrapper from "./shared/CallWrapper";
import CallWrapperHeader from "./shared/CallWrapperHeader";

const CallForm = (props) => {

  const {
    state
  } = useContext(CallsContext);

  const [contact, setContact] = useState({})

  const { register, handleSubmit, reset, errors } = useForm();
  const onSubmit = data => {
    CommonAxios.post(`${props.baseUrl}/widget/${state.widgetId}/contacts/${contact.hash_id}/update_contact`, data)
      .then((response) => {
        if (response.status === 202) {
          toastr.success("Successfully updated contact info")
        } else {
          toastr.error("Couldn't update contact info")
        }
      })
  }

  useEffect(() => {
    reset({});
    if (state !== undefined && state.rawContact !== undefined && state.rawContact !== null) {
      const { name, number, email, hash_id } = state.rawContact;
      setContact({
        hash_id: hash_id,
        name: name,
        number: number,
        email: email
      })
    }
  }, [state.rawContact])

  return (
    <CallWrapper {...props}>
      <CallWrapperHeader>Contact Details</CallWrapperHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>Name</label>
        <input
          className="form-control"
          type="text"
          name="contact[name]"
          defaultValue={contact.name ? contact.name : ""}
          ref={register}
        ></input>

        <label>Email</label>
        <input
          className="form-control"
          type="text"
          name="contact[email]"
          defaultValue={contact.email ? contact.email : ""}
          ref={register}
        ></input>

        <label>Country</label>
        <input
          className="form-control"
          type="text"
          name="contact[country]"
          defaultValue={contact.country ? contact.country : ""}
          ref={register}
        ></input>

        <input
          type="submit"
          className="form-control btn btn-primary large pull-right"
          value="Update"
        />

        {errors.exampleRequired && <span>This field is required</span>}
      </form>
    </CallWrapper>
  );
};

export default CallForm;
