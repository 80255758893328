import React, { useReducer, useEffect, useState } from "react";
import { createConsumer } from "@rails/actioncable";
import { CallsProvider } from "./NewDialer/contexts/CallsContext";
import CallProgressArea from "./NewDialer/CallProgressArea";
import CallCompletedArea from "./NewDialer/CallCompletedArea";
import ActionButtonArea from "./NewDialer/ActionButtonArea";
import DialerInfo from "./NewDialer/DialerInfo";
import BoxWithPadding from "./NewDialer/shared/BoxWithPadding";
import AutodialerMachine from "./NewDialer/machines/AutodialerMachine";

const LatestDialer = (props) => {
  const paths = {
    createCommentUrl: props.createCommentUrl,
    forceCompleteCampaignUrl: props.forceCompleteCampaignUrl,
    pauseContinueCampaignUrl: props.pauseContinueCampaignUrl,
  };

  /* States */
  const [current, send] = AutodialerMachine(props);
  const state = current.context;

  // For Debugging State Machine
  window.current = current;
  window.send = send;

  /* useEffects */

  // useEffect(() => {
  //   const cable = createConsumer(props.domainUrl);

  //   const callSequenceChannel = cable.subscriptions.create(
  //     {
  //       channel: "CallSequenceChannel",
  //       callSequenceId: props.callSequenceId,
  //     },
  //     {
  //       connected: () => {
  //         console.log("Connected to cable");
  //       },
  //       received: (data) => {},
  //     }
  //   );
  // }, []);

  return (
    <div>
      <p>Twilio Device: ${JSON.stringify(current.context.twilioDevice)}</p>
      <p>Channel: {JSON.stringify(current.context.callSequenceChannel)}</p>
      <p>Paused: {JSON.stringify(current.context.paused)}</p>
      <p>???: {JSON.stringify(current.context)}</p>
    </div>
  );
};

export default LatestDialer;
