import React, { useReducer } from "react";
import { Form } from "react-bootstrap";

const NumberArea = (props) => {
  const { numbers, dispatch, startSequence } = props;

  const handlePasteEvent = (e) => {
    const clipboardData = e.clipboardData.getData("Text");
    if (clipboardData.length > 0) {
      try {
        console.log("Setting numbers");
        const numbersArr = clipboardData.split("\n");
        dispatch({ type: "PASTE", numbers: numbersArr });
        document.getElementById("emailTextArea").value = "";
        console.log("Cleared numbers");
      } catch (err) {
        console.log("Shit something went wrong", err);
      }
    }
  };

  const markFinished = (num) => {
    console.log("Dispatching for num:", num);
    dispatch({ type: "FINISHED", number: num });
  };

  const displayNumbers = Object.keys(numbers).map((num, ix) => (
    <p key={ix}>
      {num}: {JSON.stringify(numbers[num])}
      <button onClick={() => markFinished(num)}>Finished</button>
    </p>
  ));

  return (
    <div id="number-area">
      <Form>
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            id="emailTextArea"
            as="textarea"
            rows={2}
            type="tel"
            placeholder="Enter phone numbers"
            onPaste={handlePasteEvent}
          />
          <Form.Text className="text-muted">
            Paste a list of numbers and we'll format it for you below into a
            list
          </Form.Text>
        </Form.Group>
      </Form>
      <h1>Your Numbers</h1>
      {displayNumbers}
      <button onClick={() => startSequence()}>Start Sequence</button>
    </div>
  );
};

export default NumberArea;
