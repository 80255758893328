import React, { useState, useContext } from "react";
import styled from "styled-components";
import CallWrapperHeader from "./shared/CallWrapperHeader";
import CallWrapper from "./shared/CallWrapper";
import CommonAxios from "./apis/CommonAxios";
import CallsContext from "./contexts/CallsContext";

const CommentInput = styled.input`
  :disabled {
    border: none;
    box-shadow: none;
  }
`;

const CallNotes = (props) => {
  const { state, paths } = useContext(CallsContext);
  const [comment, setComment] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const formattedComment = comment.trim();

    const data = {
      description: formattedComment
    }

    CommonAxios.post(
      `${paths.baseUrl}/calls/${state.callHashId}/save`, 
      data
    ).then((response) => {
      console.log("[CallNotes] response:", response)
      if (response.status === 201) {
        toastr.success("Successfuly saved your comment")
      } else {
        toastr.error(
          "There was an error submitting the comment, please try again"
        );
      }
    })
    .catch((err) => {
      console.log(err);
      toastr.error(err.response);
    });
  };

  const handleTextArea = (e) => {
    setComment(e.target.value);
  };

  return (
    <CallWrapper {...props}>
      <CallWrapperHeader>Comments</CallWrapperHeader>
      <form>
        <textarea
          value={comment}
          onChange={handleTextArea}
          className="form-control"
          type="textarea"
          name="call_sequence_contact[notes]"
          rows="10"
        />

        <CommentInput
          type="submit"
          className="form-control btn btn-primary large pull-right"
          value="Save"
          disabled={comment.length === 0 || state.callHashId === undefined}
          onClick={handleSubmit}
        />
      </form>
    </CallWrapper>
  );
};

export default CallNotes;
