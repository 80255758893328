import React from "react";
import styled from "styled-components";
import CallWrapperHeader from "./shared/CallWrapperHeader";
import CallWrapper from "./shared/CallWrapper";

const callScriptStyle = {
  "wordBreak": "break-word",
  "overflowWrap": "break-word",
};

const CallScript = (props) => {
  const { callScript } = props;
  return (
    <CallWrapper {...props}>
      <CallWrapperHeader>Call Script</CallWrapperHeader>
      <div
        style={callScriptStyle}
        dangerouslySetInnerHTML={{ __html: callScript }}
      />
    </CallWrapper>
  );
};

export default CallScript;
