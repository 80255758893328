import React, { useReducer, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DayPicker, { DateUtils } from 'react-day-picker';
import CustomAvailabilityTimings from "./DateOverride/CustomAvailabilityTimings";
import moment from 'moment'
import _ from 'lodash';
import $ from 'jquery';
import "eonasdan-bootstrap-datetimepicker";

const DateOverride = (props) => {
  const initialState = {
    showModal: false,
    customAvailabilities: props.custom_availabilities,
    selectedDays: [],
    firstDaySelected: null,
    newTimings: [],
    showTimingOnModal: false
  };

  useEffect(() => {
    initialDatetimePicker()
  });

  /* Reducers */
  const stateReducer = (prevState, nextState) => ({
    ...prevState,
    ...nextState,
  });

  const [state, setState] = useReducer(stateReducer, initialState);

  const handleClose = () => setState({showModal: false});
  const handleShow = () => setState({showModal: true});
  const [customDays, setAvailabilities] = useState(state.customAvailabilities.map( (t) => new Date(t.date) ))
  const modifiers = {
    weekEnd: { daysOfWeek: [6, 7] },
  };

  const initialDatetimePicker = ()=> {
    $(".timepicker").datetimepicker({
        format: "HH:mm",
        useCurrent: false
    }).on("dp.change", function(event) {
      if ($(this).closest(".timing").data("timing-id") != undefined) {
        let value = $(this).find("input").val()
        let id = $(this).closest(".timing").data("timing-id")
        updateNewTiming(value, id, $(this).hasClass("start-time"))
      }
      check_working_hours(event, $(this));
    });
  }

  const handleDayClick = (day, { selected }) => {
    if (day < moment().subtract(1, 'day')) {
      return // Disable past dates
    }

    const selectedDays = state.selectedDays.concat();
    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }
    setState({ selectedDays });
    let firstSelected = selectedDays.length !== 0 ? moment(selectedDays[0]).format('YYYY-MM-DD') : null ;
    let currentOverrideDate = state.customAvailabilities.find(o => o.date === firstSelected)

    const newTimings = state.newTimings
    if (selectedDays.length === 1) {
      if (state.firstDaySelected !== selectedDays[0]) {
        setState({firstDaySelected: selectedDays[0]})
        if (currentOverrideDate !== undefined) {
          setState({ newTimings: currentOverrideDate.custom_timings })
        } else {
          setState({ newTimings: [{start: "09:00", stop: "10:00", id: (new Date().getTime()), new_timing: true}] })
        }
      }
    }
  }

  const applyTimings = () => {
    let next = true;
    state.newTimings.map((time, index) => {
      if (time.start > time.stop) {
        toastr.error( "Choose an end time later than the start time.");
        next = false
      }
    })

    if (next) {
      let newDate = {}
      state.selectedDays.map((date, index) => {
        newDate = {
          id: "",
          date: moment(date).format('YYYY-MM-DD'),
          custom_timings: state.newTimings,
          new_date: true,
        }
        addOrReplace(newDate)
      })

      setState({
        showModal: false,
        newTimings: [],
        selectedDays: [],
        customAvailabilities: state.customAvailabilities,
      })
      setAvailabilities(state.customAvailabilities.map( (t) => new Date(t.date) ))
    }
  }

  let arrCustomAvailabilities = state.customAvailabilities, arrIndex = {};

  function addOrReplace(object) {
    var index = arrCustomAvailabilities.map(function(x) {return x.date; }).indexOf(object.date);
    if(index === -1) {
        index = arrCustomAvailabilities.length;
        arrIndex[object.date] = index;
    }
    arrCustomAvailabilities[index] = object;
  }

  const removeDate = (date) => {
    var array = [...state.customAvailabilities];
    var index = array.map(function(x) {return x.date; }).indexOf(date);
    if (index !== -1) {
      array.splice(index, 1);
      setState({customAvailabilities: array});
    }
    setAvailabilities(array.map( (t) => new Date(t.date) ))
  }

  const addNewTiming = () => {
    let newTimings = state.newTimings
    setState({ newTimings: newTimings.concat([{start: "09:00", stop: "10:00", id: (new Date().getTime()), new_timing: true }]) })
  }

  const updateNewTiming = (value, id, start_or_stop) => {
    var index = _.findIndex(state.newTimings, {id: id});
    let time = _.find(state.newTimings, { id: id});
    let update = {}
    update[`${start_or_stop ? "start" : "stop"}`] = value
    state.newTimings.splice(index, 1, {...time, ...update})
  }

  const removeNewTiming = (time) => {
    var result = _.remove(state.newTimings, function(object) {
      return object.id === time;
    });
    setState({newTimings: state.newTimings})
  }

  const renderDay = (day) => {

    let schedules = customDays.map(d => moment(d).format('YYYY-MM-DD'))
    let scheduled = schedules.includes(moment(day).format('YYYY-MM-DD'))

    const date = day.getDate();
    const dateStyle = {
      bottom: 0,
      right: 0,
    };
    const scheduleStyle = { textAlign: 'left' };
    const cellStyle = {
      height: 20,
      width: 20,
      position: 'relative',
    };
    const dotStyle = {
      fontSize: '12px' ,
      position: 'absolute' ,
      top: '19px' ,
      left: '12px' ,
    }
    return (
      <div style={cellStyle}>
        <div style={dateStyle}>{date}</div>

        {scheduled && (<div style={dotStyle}>•</div>) }
      </div>
    );
  }

  const TimingItems = ({custom_timings}) => {
    let list = []
    custom_timings.map((timing, index) =>
      list.push(
        <>
          <div className="row timing" key={index} data-timing-id={timing.id}>
            <div className="col-xs-4 p-0 start-time-input">
              <div className="form-group">
                <div id="datetimepicker3" className="input-group timepicker start-time custom-timing-fields">
                  <input type="text" defaultValue={timing.start} className="form-control input-group-addon time-input-text" />
                  <span className="input-group-addon">
                    <i className="fa fa-caret-down" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xs-1 mt-10">
              <span className="time-text">
                _
              </span>
            </div>
            <div className="col-xs-4 p-0 end-time-input">
              <div className="form-group">
                <div id="datetimepicker3" className="input-group timepicker pull-right end-time custom-timing-fields">
                  <input type="text" defaultValue={timing.stop} className="form-control input-group-addon time-input-text" />
                  <span className="input-group-addon">
                    <i className="fa fa-caret-down" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xs-1 mt-10">
              <i className="fa fa-trash-o delete-icon" aria-hidden="true" style={{ color: '#003df2'}} onClick={() => removeNewTiming(timing.id)} />
            </div>
          </div>
        </>
      )
    )
    list.push(
      <>
        <Button bsStyle="link pl-0" onClick={addNewTiming} >
          + Add hours
        </Button>
      </>
    )
    return (
      <>
        { (state.selectedDays.length !== 0) && list }
      </>
    );
  }

  return (
    <>
      <div className="modal-container">
        <Modal
          show={state.showModal}
          onHide={handleClose}
          container={this}
          aria-labelledby="contained-modal-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title">
              Choose the specific time and date(s)
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-xs-6" style={ {borderRight: '1px solid #EDEDED'} }>
                <DayPicker
                  selectedDays={state.selectedDays}
                  onDayClick={handleDayClick}
                  modifiers={modifiers}
                  disabledDays={{ before: new Date() }}
                  renderDay={renderDay}
                />
              </div>
              <div className="col-xs-6 pl-30 mt-20 pr-0">
                <TimingItems
                  className="pull-right"
                  custom_timings={state.newTimings}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              aria-label="close"
              type="button"
              data-dismiss="modal"
              bsStyle="link"
              className="btn-grey btn-md mr-25 mt-5"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button bsStyle="primary" onClick={applyTimings} >
              Apply
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div id="custom-availability-timings">
        <h4 className="mb-0 semibold-text">Date override</h4>
        <Button bsStyle="link" onClick={handleShow} >
          + Add date override
        </Button>
        <br/>
        <CustomAvailabilityTimings
          custom_availabilities={state.customAvailabilities}
          remove_date={(date) => removeDate(date)}
          key={new Date().getTime()}
        />
      </div>
    </>
  );
}

export default DateOverride;
