import React, { useEffect } from "react";
import BoxWithPadding from "./shared/BoxWithPadding";

const CallCompletedArea = (props) => {

  useEffect(() => {
    location.href = `${props.summaryPath}`;
  });

  return (
    <BoxWithPadding>
      <div className="row mb-25 mt-50">
        <div className="col-md-12 text-center">
          <h1>CAMPAIGN COMPLETED!</h1>
          <p>Redirecting you to the summary page in a bit</p>
        </div>
      </div>
    </BoxWithPadding>
  );
};

export default CallCompletedArea;
