import React from "react";

const CountrySelectBox = (props) => {
  console.log("In Country Select Box, props:", props);
  const CountryOptions = props.countries.map((country) => {
    const flagClass = "flag flag-" + country.code;

    return (
      <li key={country.cc}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            props.handleOnChange(country.cc);
          }}
        >
          <div className={flagClass}></div>
          <span>
            {" "}
            {country.name} (+{country.cc})
          </span>
        </a>
      </li>
    );
  });

  return (
    <div className="input-group-btn">
      <button
        type="button"
        className="btn btn-default dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        +<span className="country-code">{props.countryCode}</span>
        <i className="fa fa-caret-down"></i>
      </button>
      <ul className="dropdown-menu">{CountryOptions}</ul>
    </div>
  );
};

export default CountrySelectBox;
