import React, { useContext } from "react";
import styled from "styled-components";
import CallsContext from "./contexts/CallsContext";
import DTMFTone from "./DTMFTone";
import { PhoneCall } from "react-feather";
import SvgHangDownButton from "./icons/svg_hang_down_button";
import SvgMuteButton from "./icons/svg_mute_button";
import SvgRecordCallButton from "./icons/svg_record_call_button";
import CallWrapper from "./shared/CallWrapper";
import { COLORS } from '../shared/constants'

const NumberBox = styled.div`
  margin: 0 auto;
`;

const InnerNumberBox = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 9px 12px;
  margin: 0 20px;
  border: 2px solid ${(props) => props.color};
  border-radius: 4px;
  color: ${(props) => props.color};
  font-size: 20px;
  font-weight: bold;
  text-align: center;

  svg {
    margin-right: 0.25em;
  }
`;

const MiddleBox = styled.div`
  margin: 3em 0;
`;

const NameBox = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: normal;
`;

const TimerBox = styled.div`
  font-size: 36px;
  font-weight: bold;
  line-height: normal;
`;

const IconsBox = styled.div`
  display: flex;
`;

const IconsGroup = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;

  svg:not(:last-of-type) {
    margin-right: 10px;
  }

  svg:hover {
    cursor: pointer;
  }
`;

const smallIconSize = "40px";
const mainIconSize = "50px";

const CallInfo = (props) => {
  /* states */
  const { state, countdownTimer, hangDownCall } = useContext(CallsContext);
  const hasNumber = state.currentNumber !== "";

  const formattedTimer = (timer) => {
    var date = new Date(0);
    date.setSeconds(timer); // specify value for SECONDS here
    var timeString = date.toISOString().substr(11, 8);
    return timeString
  }

  return (
    <CallWrapper textAlign="center">
      <NumberBox>
        <InnerNumberBox color={hasNumber ? COLORS.green : COLORS.grey}>
          <PhoneCall fill={hasNumber ? COLORS.green : COLORS.grey} disabled={hasNumber} />
          {hasNumber ? state.currentNumber : "No number"}
        </InnerNumberBox>
      </NumberBox>
      <MiddleBox>
        <NameBox>{state.triggeredCountdownTimer && !state.onPhone && state.countdownTimerInterval !== undefined ? "Timer in progress" : state.log}</NameBox>
        <TimerBox>{state.triggeredCountdownTimer && !state.onPhone && state.countdownTimerInterval !== undefined ? countdownTimer : formattedTimer(state.timer)}</TimerBox>
        {
          state.onPhone ?
            <DTMFTone twilioDevice={state.twilioDevice} />
            : ""
        }
      </MiddleBox>
      <IconsBox>
        <IconsGroup>
          {/* <SvgMuteButton
            height={smallIconSize}
            width={smallIconSize}
            disabled={true}
          /> */}
          <SvgHangDownButton
            height={mainIconSize}
            width={mainIconSize}
            onClick={hangDownCall}
            color={hasNumber ? COLORS.red : COLORS.grey}
            disabled={hasNumber}
          />
          {/* <SvgRecordCallButton
            height={smallIconSize}
            width={smallIconSize}
            disabled={true}
          /> */}
        </IconsGroup>
      </IconsBox>
    </CallWrapper>
  );
};

export default CallInfo;
