import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import CallInfo from "./CallInfo";
import CallForm from "./CallForm";
import CallNotes from "./CallNotes";
import CallScript from "./CallScript";
import CallQueue from "./CallQueue";
import CallsContext from "./contexts/CallsContext";

const CallProgressAreaWrapper = styled.div`
  div[disabled] {
    pointer-events: none;
    opacity: 0.4;
  }
`;

const CallProgressArea = () => {
  const { state, paths } = useContext(CallsContext);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(
      state.currentNumber === "" ||
      state.currentNumber === undefined ||
      state.currentNumberId === "" ||
      state.currentNumberId === undefined
    );
  }, [state.currentNumber, state.currentNumberId]);

  return (
    <CallProgressAreaWrapper className="row mb-25 mt-50">
      <div className="col-md-4">
        <CallInfo />
        {/* <CallQueue /> */}
      </div>
      <div className="col-md-4">
        <CallForm disabled={disabled} baseUrl={paths.baseUrl} />
      </div>
      <div className="col-md-4">
        <CallScript disabled={disabled} callScript={state.callScript} />
        <CallNotes disabled={disabled} />
      </div>
    </CallProgressAreaWrapper>
  );
};

export default CallProgressArea;
