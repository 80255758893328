import React from "react";
import styled from "styled-components";

const KeyArea = styled.div`
  margin-top: 20px;
`

const NumberPad = styled.button`
  margin: 3px;
`

const DTMFTone = (props) => {
  // Handle numeric buttons
  const sendDigit = (digit) => {
    props.twilioDevice.activeConnection().sendDigits(digit);
  };

  return (
    <KeyArea>
      <div className="key-row">
        <NumberPad
          className="btn btn-circle btn-default"
          onClick={() => sendDigit("1")}
        >
          1
        </NumberPad>
        <NumberPad
          className="btn btn-circle btn-default"
          onClick={() => sendDigit("2")}
        >
          2
        </NumberPad>
        <NumberPad
          className="btn btn-circle btn-default"
          onClick={() => sendDigit("3")}
        >
          3
        </NumberPad>
      </div>
      <div className="key-row">
        <NumberPad
          className="btn btn-circle btn-default"
          onClick={() => sendDigit("4")}
        >
          4
        </NumberPad>
        <NumberPad
          className="btn btn-circle btn-default"
          onClick={() => sendDigit("5")}
        >
          5
        </NumberPad>
        <NumberPad
          className="btn btn-circle btn-default"
          onClick={() => sendDigit("6")}
        >
          6
        </NumberPad>
      </div>
      <div className="key-row">
        <NumberPad
          className="btn btn-circle btn-default"
          onClick={() => sendDigit("7")}
        >
          7
        </NumberPad>
        <NumberPad
          className="btn btn-circle btn-default"
          onClick={() => sendDigit("8")}
        >
          8
        </NumberPad>
        <NumberPad
          className="btn btn-circle btn-default"
          onClick={() => sendDigit("9")}
        >
          9
        </NumberPad>
      </div>
      <div className="key-row">
        <NumberPad
          className="btn btn-circle btn-default"
          onClick={() => sendDigit("*")}
        >
          *
        </NumberPad>
        <NumberPad
          className="btn btn-circle btn-default"
          onClick={() => sendDigit("0")}
        >
          0
        </NumberPad>
        <NumberPad
          className="btn btn-circle btn-default"
          onClick={() => sendDigit("#")}
        >
          #
        </NumberPad>
      </div>
    </KeyArea>
  );
};

export default DTMFTone;
