import React, { useReducer, useEffect, useState, useRef } from "react";
import { Device } from "twilio-client";
import { createConsumer } from "@rails/actioncable";
import { CallsProvider } from "./NewDialer/contexts/CallsContext";
import CallProgressArea from "./NewDialer/CallProgressArea";
import CallCompletedArea from "./NewDialer/CallCompletedArea";
import ActionButtonArea from "./NewDialer/ActionButtonArea";
import DialerInfo from "./NewDialer/DialerInfo";
import BoxWithPadding from "./NewDialer/shared/BoxWithPadding";
import CommonAxios from "./NewDialer/apis/CommonAxios";
import { path } from "xstate/lib/utils";

const DialerApp = (props) => {
  const initialState = {
    widgetId: props.widgetId,
    userId: props.userId,
    twilioDevice: undefined,
    timer: 0,
    timeBetweenCalls: props.settings.time_between_calls,
    waitingTime: props.settings.waiting_time,
    paused: props.paused,
    muted: false,
    log: "Connecting...",
    onPhone: false,
    numberStatus: undefined,
    currentNumber: "",
    currentNumberId: "",
    isValidNumber: false,
    numberQueue: [],
    createdAt: props.createdAt,
    progress: props.progress,
    status: props.status,
    settings: props.settings,
    callScript: props.callScript,
    triggeredCountdownTimer: false,
    countdownTimerInterval: undefined,
  };

  // For tracking previous state using react's useRef()
  const prevStateRef = useRef();

  const [countdownTimer, setCountdownTimer] = useState(
    props.settings.time_between_calls
  );

  const paths = {
    baseUrl: props.baseUrl,
    createCommentUrl: props.createCommentUrl,
    forceCompleteCampaignUrl: props.forceCompleteCampaignUrl,
    pauseContinueCampaignUrl: props.pauseContinueCampaignUrl,
    nextNumberUrl: props.nextNumberUrl,
    summaryPath: props.summaryPath,
    updateContactPath: props.updateContactPath,
  };

  /* Reducers */

  const stateReducer = (prevState, nextState) => ({
    ...prevState,
    ...nextState,
  });

  /* States */
  const [state, setState] = useReducer(stateReducer, initialState); // TODO: switch to machine context???
  const cable = createConsumer(props.domainUrl);
  const [callSequenceChannel, setCallSequenceChannel] = useState(undefined); // TODO: might need to separate context from this. Then pass props into context

  /* Effects */

  // Initialize after component creation
  useEffect(() => {
    // Fetch Twilio capability token from our Node.js server
    if (props.uid !== undefined && props.uid !== "" && props.uid.length > 0) {
      connectTwilioDevice();
    }
  }, [props.uid]);

  // During a call, update the currentTimer
  useEffect(() => {
    if (state.onPhone) {
      const callProgressTimerInterval = setTimeout(() => {
        const prevTimer = state.timer;
        setState({
          timer: prevTimer + 1,
        });
      }, 1000);

      setState({
        callProgressTimerInterval: callProgressTimerInterval,
      });
    } else {
      setState({
        timer: 0,
        callProgressTimerInterval: undefined,
      });
    }
  }, [state.timer, state.onPhone]);

  // This fires only when countdown has lapsed to 0
  useEffect(() => {
    if (
      state.triggeredCountdownTimer &&
      countdownTimer <= 0 &&
      !state.onPhone &&
      !state.paused
    ) {
      CommonAxios.get(paths.nextNumberUrl)
        .then((response) => {
          if (response.status === 200 && response.data.next_number !== null) {
            toastr.success("Got next number, calling now...");
            setState({
              progress: response.data.progress,
            });
            const new_number_object = {
              country_code: response.data.next_number.country_code,
              raw_number: response.data.next_number.raw_number,
              number: response.data.next_number.number,
              numberId: response.data.next_number.hash_id,
            };
            newNumber(new_number_object);
            newContact(response);
            handleToggleCall(new_number_object);
          } else {
            toastr.error(
              "There was an error getting next number, please try again later"
            );
          }
        })
        .catch((err) => {
          console.log(err);
          toastr.error(JSON.stringify(err));
        });
      setCountdownTimer(props.settings.time_between_calls);
      clearInterval(state.countdownTimerInterval);
      setState({
        triggeredCountdownTimer: false,
        countdownTimerInterval: undefined,
      });
    }
  }, [
    countdownTimer,
    state.triggeredCountdownTimer,
    state.paused,
    state.onPhone,
  ]);

  // This fires when triggeredCountdownTimer is true
  useEffect(() => {
    if (state.triggeredCountdownTimer) {
      if (!state.paused) {
        const countdownTimerInterval = setInterval(() => {
          setCountdownTimer((countdownTimer) => countdownTimer - 1);
        }, 1000);
        setState({
          countdownTimerInterval: countdownTimerInterval,
        });
      }
    } else {
      clearInterval(state.countdownTimerInterval);
    }
  }, [state.triggeredCountdownTimer, state.paused]);

  /* Methods */

  const connectTwilioDevice = () => {
    $.getJSON(props.authenticateUrl, {
      uid: props.uid,
      call_type: "outbound",
    })
      .done(function (data) {
        setState({
          twilioDevice: Device.setup(data.token, {
            codecPreferences: ["opus", "pcmu"],
            fakeLocalDTMF: true,
            enableRingingState: true,
          }),
        });
      })
      .fail(function (err) {
        console.log(err);
        setState({ log: "Unable to connect to the calling device" });
      });

    // Configure event handlers for Twilio Device
    Device.on("disconnect", () => {
      setState({
        onPhone: false,
        log: "Call ended.",
      });
    });

    Device.on("ready", () => {
      console.log("Device is ready");
      setState({
        log: "Connected",
      });
    });
  };

  /*
      pauseDialer() – common method exposed to interface to allow user 
                      to pause Autodialer (for the user OR for all users)

      - pauseTypes: ["me", "campaign"]
    */
  const pauseDialer = (pauseType = "me") => {
    // Clear the interval for timer regardless of the type of pause
    clearInterval(state.countdownTimerInterval);
    setState({
      paused: true,
      triggeredCountdownTimer: false,
      countdownTimerInterval: undefined,
    });

    // Set the relevant state based on pause type
    if (pauseType == "campaign") {
      setState({
        campaignPaused: true,
      });
    }
  };

  const requestNumber = () => {
    if (state.onPhone) {
      toastr.error("Currently on phone, can't trigger new call");
    } else if (state.triggeredCountdownTimer && countdownTimer > 0) {
      if (state.paused) {
        toastr.error("You are currently paused!");
      } else {
        toastr.error("Already counting down");
      }
    } else if (state.status === "completed") {
      toastr.info("Campaign is already completed");
    } else {
      if (
        !state.triggeredCountdownTimer &&
        state.countdownTimerInterval == undefined
      ) {
        setState({
          triggeredCountdownTimer: true,
          // countdownTimerInterval: countdownTimerInterval,
        });
      }
    }
  };

  const newContact = (response) => {
    setState({
      rawContact: response.data.contact,
    });
  };

  const newNumber = (numObj) => {
    setState({
      currentNumber: numObj.raw_number ? numObj.raw_number : "No Number",
      currentNumberId: numObj.numberId,
    });
  };

  const processNumberUpdate = (data) => {
    const { status, call_sequence_number_id } = data;
    setState({
      numberStatus: status,
    });
    toastr.info("Number status updated as " + status);
    if (
      [
        "uncalled",
        "called",
        "no_answer",
        "rejected",
        "answered",
        "busy",
      ].includes(status)
    ) {
      setState({
        onPhone: false,
      });
      setCountdownTimer(props.settings.time_between_calls);
      requestNumber();
    }
  };

  const updateProgress = (data) => {
    console.log("Updating progress. data:", data);
    if (data.progress !== undefined && data.progress !== null) {
      setState({
        progress: data.progress,
      });
    }
  };

  const processCallDetail = (data) => {
    setState({
      callHashId: data["callHashId"],
    });
  };

  const processData = (callSequenceChannel, data) => {
    const { action, status } = data;
    updateProgress(data);

    switch (action) {
      case "NEW_NUMBER":
        const new_number_object = data["new_number_object"];
        // newNumber(new_number_object);s
        // callingNumber(callSequenceChannel, new_number_object);
        break;
      case "NO_NEW_NUMBER":
        newNumber({});
        break;
      case "STATUS_UPDATE":
        setState({ status: status });
        break;
      case "NUMBER_UPDATE":
        processNumberUpdate(data);
        break;
      case "CALL_DETAIL":
        processCallDetail(data);
        break;
      default:
        break;
    }
  };

  // Setup ActionCable to run Call Sequence
  useEffect(() => {
    if (state.twilioDevice !== undefined && state.log === "Connected") {
      const callSequenceChannel = cable.subscriptions.create(
        {
          channel: "CallSequenceChannel",
          callSequenceId: props.callSequenceId,
        },
        {
          connected: () => {
            setCallSequenceChannel(callSequenceChannel);
            requestNumber();
          },
          received: (data) => {
            processData(callSequenceChannel, data);
          },
        }
      );
    }
  }, [state.twilioDevice, state.log]);

  /* Handle uncalled numbers when Twilio has error  */
  const uncalledNumber = (callSequenceChannel, numberId) => {
    if (status === "calling") {
      callSequenceChannel.send({
        callSequenceId: props.callSequenceId,
        numberId: numberId,
        action: "uncalled_number",
      });
    }
  };

  useEffect(() => {
    if (callSequenceChannel !== undefined && state.currentNumberId !== "") {
      Device.on("error", () => {
        uncalledNumber(callSequenceChannel, state.currentNumberId);
      });

      Device.on("cancel", () => {});
    }
  }, [callSequenceChannel, state.currentNumberId]);

  // Handle muting
  const handleToggleMute = () => {
    let muted = !state.muted;

    setState({ muted: muted });
    Device.activeConnection().mute(muted);
  };

  // Make an outbound call with the current number,
  // or hang up the current call
  const handleToggleCall = (number_object) => {
    if (!state.onPhone) {
      setState({
        muted: false,
        onPhone: true,
        triggeredCountdownTimer: false,
        countdownTimerInterval: undefined,
        log: "Calling " + number_object["raw_number"],
      });
      setCountdownTimer(props.settings.time_between_calls);
      // make outbound call with current number
      Device.disconnectAll();
      Device.connect({
        calls: JSON.stringify({ number: number_object["raw_number"] }),
        call_type: "outbound",
        call_sequence_id: props.callSequenceId,
        call_sequence_number_id: number_object["numberId"],
        user_id: props.userId,
      });
    } else {
      // hang up call in progress
      Device.disconnectAll();
    }
  };

  const hangDownCall = () => {
    if (hasNumber()) {
      setState({
        muted: false,
        onPhone: false,
        currentNumber: undefined,
        currentNumberId: undefined,
      });
      Device.disconnectAll();
    } else {
      toastr.warning("You can't do this as there's no number set yet");
    }
  };

  const hasNumber = () => {
    return state.currentNumber !== "";
  };

  return (
    <CallsProvider
      value={{
        state,
        countdownTimer,
        setCountdownTimer,
        setState,
        paths,
        hangDownCall,
        requestNumber,
        pauseDialer,
        callSequenceChannel,
      }}
    >
      <div id="dialer-app">
        <div className="call-sequences">
          <div className="center-container">
            <div className="page-header-container">
              <a href={`/widget/${props.widgetId}/outbound/campaigns`}>
                &lt; All Campaigns
              </a>
              <span style={{ padding: "0 1rem" }}>/</span>
              <a
                href={`/widget/${props.widgetId}/outbound/campaigns/${props.callSequenceId}`}
              >
                Campaign {props.callSequenceId}
              </a>
              <div className="page-header">
                Manage&nbsp;Campaign {props.callSequenceId}
              </div>
            </div>
            <BoxWithPadding>
              <div className="row">
                <div className="call-sequence-info col-md-7">
                  <DialerInfo
                    createdAt={state.createdAt}
                    progress={state.progress}
                    status={state.status}
                  />
                </div>
                <div className="col-md-5 text-right">
                  {state.status !== "completed" && <ActionButtonArea />}
                </div>
              </div>
            </BoxWithPadding>
            {state.status === "completed" ? (
              <CallCompletedArea summaryPath={paths.summaryPath} />
            ) : (
              <CallProgressArea />
            )}
          </div>
        </div>
      </div>
    </CallsProvider>
  );
};

export default DialerApp;
