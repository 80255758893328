import React from "react";
import styled from "styled-components";

const BoxWithPadding = styled.div`
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  padding: 20px !important;
  overflow: inherit;
`;

export default BoxWithPadding;
