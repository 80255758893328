import React from "react";

export const CustomAvailabilityTimings = (props) => {
  const { custom_availabilities, remove_date } = props
  var days = []
  custom_availabilities.map((data, index) => {
    let initial = data.new_date ? "new_custom_availabilities" : "custom_availabilities"
    days.push(
      <div className="custom_availabilities">
        <div className="row pt-30" id={`timing-${data.id}`} key={data.id}>
          <div className="col-xs-3 pr-0">
            <div className="form-group">
              <input className="form-control" defaultValue={data.date} type="date" name={"manager["+initial+"][" + data.id + "][date]"} />
            </div>
          </div>
          <div className="col-xs-2 mt-10">
            <a onClick={() => remove_date(data.date)}>
              <i className="fa fa-trash-o delete-icon" aria-hidden="true" style={{ color: '#003df2'}} />
            </a>
          </div>
        </div>
        <div className="row custom-timings">
          <CustomTimings
            custom_timings={data.custom_timings}
            date_id={data.id}
            initial={initial}
            new_timing={data.new_date}
          />
        </div>
      </div>
    )
  });
  return days;
};

const CustomTimings = ({custom_timings, date_id, initial, new_timing}) => {
  var timings = []
  custom_timings.map((data, index) => {
    timings.push(
      <div className="col-xs-12 pr-0" key={index}>

        <div className="row">
          <div className="col-xs-3 pr-0 start-time-input">
            <div className="form-group">
              <div className="input-group timepicker start-time custom-timing-fields" id="datetimepicker3">
                <input
                  className="form-control input-group-addon time-input-text"
                  type="text"
                  defaultValue={data.start}
                  name={"manager["+initial+"]["+ date_id +"][custom_timings_attributes]["+ index +"][start]"}
                />
                <span className="input-group-addon">
                  <i className="fa fa-caret-down" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-xs-1 mt-10">
            <span className="time-text"> _ </span>
          </div>
          <div className="col-xs-3 p-0 end-time-input">
            <div className="form-group">
              <div className="input-group timepicker pull-right end-time custom-timing-fields" id="datetimepicker3">
                <input
                  className="form-control input-group-addon time-input-text"
                  type="text"
                  defaultValue={data.stop}
                  name={"manager["+initial+"]["+ date_id +"][custom_timings_attributes]["+ index +"][stop]"}
                />
                <span className="input-group-addon">
                  <i className="fa fa-caret-down" />
                </span>
              </div>
            </div>
          </div>
          {!new_timing && <input defaultValue={0} type="hidden" name={"manager[custom_availabilities][" + date_id + "][custom_timings_attributes]["+index+"][_destroy]"} />}
          {!new_timing && <input defaultValue={data.id} type="hidden" name={"manager[custom_availabilities][" + date_id + "][custom_timings_attributes]["+index+"][id]"} />}
        </div>
      </div>
    )
  })
  return timings
};

export default CustomAvailabilityTimings;
