import React from "react";
import styled from "styled-components";

const CallWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  padding: 20px !important;
  text-align: ${(props) =>
    props.textAlign === undefined ? "left" : props.textAlign};
`;

export default CallWrapper;
