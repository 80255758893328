import React from "react";

const SvgMuteButton = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 50 50" fill="none" {...props}>
      <circle cx={25} cy={25} r={24.5} stroke="#000" />
      <path
        clipRule="evenodd"
        d="M23.276 18.965l-4.31 3.449h-3.449v5.172h3.448l4.31 3.449v-12.07 0z"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.62 22.414l-5.172 5.172M28.448 22.414l5.173 5.172"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgMuteButton;
