import React from "react";

const SvgRecordCallButton = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 50 50" fill="none" {...props}>
      <circle cx={25} cy={25} r={24.5} stroke="#000" />
      <path
        clipRule="evenodd"
        d="M19.396 28.448a3.88 3.88 0 100-7.758 3.88 3.88 0 000 7.758zM30.603 28.448a3.88 3.88 0 100-7.758 3.88 3.88 0 000 7.758z"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.396 28.448h11.207"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgRecordCallButton;
