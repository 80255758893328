import React from "react";
import DialerInfo from "../DialerInfo";
import BoxWithPadding from "../shared/BoxWithPadding";

const CampaignSummary = (props) => {

    return (
        <>
            <BoxWithPadding>
                <div className="row">
                    <div className="call-sequence-info col-md-7">
                        <DialerInfo createdAt={props.createdAt} progress={props.progress} status={props.status} />
                    </div>
                </div>
            </BoxWithPadding>
            <div className="row">
                <div className="call-sequence-info col-md-3">
                    <BoxWithPadding>
                        <strong>Completion Rate</strong>
                        <h4>{props.completionRate}%</h4>
                    </BoxWithPadding>
                </div>
                <div className="call-sequence-info col-md-3">
                    <BoxWithPadding>
                        <strong>Connection Rate</strong>
                        <h4>{props.connectionRate}%</h4>
                    </BoxWithPadding>
                </div>
                <div className="call-sequence-info col-md-3">
                    <BoxWithPadding>
                        <strong>Cost</strong>
                        <h4>${props.totalCost}</h4>
                    </BoxWithPadding>
                </div>
                <div className="call-sequence-info col-md-3">
                    <BoxWithPadding>
                        <strong>Average Talking Time</strong>
                        <h4>{props.averageTalkingTime}s</h4>
                    </BoxWithPadding>
                </div>
            </div>
        </>
    );
};

export default CampaignSummary;
