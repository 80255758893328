import React from "react";
import { Device } from "twilio-client";
import { Machine, assign } from "xstate";
import { useMachine } from "@xstate/react";

/* TODOS
==================

This is in case i get lost in rabbit holes because rabbit holes are real


1. CRITICAL: find a way to create Device, then assign it to context. Through swizec's useAuth library, we figured out we want to use an initial event's entry action/method to create the object. After which, we will send it to the next event where it assumes the Device has been created. And we use an entry action/method to set this into context.

I mean, I would wonder why can't I just do it in a single state, "AUTHENTICATING" and if it successfully assigns the Device object to the machine's context, all I'd have to do is use a guarded transition to "AUTHENTICATED".

(maybe ask Swizec later – need to come up with concrete examples not related to work)

2. CRITICAL: Once I'm able to set the device, I can do the same thing for ActionCable / sockets. Our hypothesis with the cable issue... is that if we shift it out, it might not have a circular reference issue with "subscriptions" or whatever anymore.

Also, as mentioned in the morning standup, the issue

*/

/* Machine */

const AutodialerMachine = (props) => {
  /* Guards */

  const deviceAndChannelReady = (context, event) => {
    return (
      context.device !== undefined && context.callSequenceChannel !== undefined
    );
  };

  /* Actions */

  function processData(data) {
    console.log("Processing incoming data", data);
    const { action, status } = data;
    switch (action) {
      case "NEW_NUMBER":
        console.log("GOT NEW NUMBER", data);
        const new_number_object = data["new_number_object"];
        // send new_number and call_number later somewhere
        break;
      case "NO_NEW_NUMBER":
        console.log("NO NEW NUMBER", data);
        // newNumber({});
        break;
      case "STATUS_UPDATE":
        setState({ status: status });
        break;
      case "NUMBER_UPDATE":
      // processNumberUpdate(data);
      default:
        break;
    }
  }

  /* Initial Context */

  const initialContext = {
    twilioDevice: undefined,
    callSequenceChannel: undefined,
    timer: "0:00",
    timeBetweenCalls: props.settings.time_between_calls,
    waitingTime: props.settings.waiting_time,
    paused: true,
    muted: false,
    log: "Connecting...",
    onPhone: false,
    currentNumber: "",
    currentNumberId: "",
    isValidNumber: false,
    numberQueue: [],
    createdAt: props.createdAt,
    progress: props.progress,
    status: props.status,
    settings: props.settings,
  };

  /* Debugging */

  // inspect({
  //   url: "https://statecharts.io/inspect",
  //   iframe: false,
  // });

  /* Actual Machine */

  const machine = new Machine(
    {
      id: "autodialerMachine",
      initial: "setup",
      context: initialContext,
      states: {
        setup: {
          type: "parallel",
          states: {
            setupDevice: {
              initial: "connecting",
              states: {
                connecting: {
                  on: {
                    CONNECT_DEVICE: {
                      target: "connected",
                      entry: "createDevice",
                    },
                    FAILURE: "failure",
                  },
                },
                failure: {},
                connected: {
                  on: {
                    DEVICE_CONNECTED: {
                      entry: "setDevice",
                    },
                  },
                },
              },
            },
            setupChannel: {
              initial: "connecting",
              states: {
                connecting: {
                  on: {
                    CHANNEL_CONNECTED: {
                      target: "connected",
                      // actions: "setChannel",
                    },
                    CHANNEL_FAIL: "failure",
                  },
                },
                failure: {},
                connected: {},
              },
            },
          },
          on: {
            SETUP_COMPLETE: {
              target: "paused",
              cond: deviceAndChannelReady,
            },
          },
        },
        paused: {
          on: {
            START: "ongoing",
            FAIL: "failure",
          },
        },
        ongoing: {
          initial: "getting_number",
          states: {
            getting_number: {
              on: {
                GET_NUMBER_SUCCESS: "calling",
                GET_NUMBER_FAILURE: "failure",
              },
            },
            failure: {
              entry: ["saveErrorToContext"],
            },
            calling: {
              initial: "dialing",
              context: {
                elapsed: 0,
                duration: 5,
                interval: 0.1,
              },
              states: {
                dialing: {
                  invoke: {
                    src: (context) => (cb) => {
                      const interval = setInterval(() => {
                        cb("TICK");
                      }, 1000 * context.interval);

                      return () => {
                        clearInterval(interval);
                      };
                    },
                  },
                  on: {
                    "": {
                      target: "paused",
                      cond: (context) => {
                        return context.elapsed >= context.duration;
                      },
                    },
                    TICK: {
                      actions: assign({
                        elapsed: (context) =>
                          +(context.elapsed + context.interval).toFixed(2),
                      }),
                    },
                  },
                },
                paused: {
                  on: {
                    "": {
                      target: "dialing",
                      cond: (context) => context.elapsed < context.duration,
                    },
                  },
                },
              },
              on: {
                "DURATION.UPDATE": {
                  actions: assign({
                    duration: (_, event) => event.value,
                  }),
                },
                RESET: {
                  actions: assign({
                    elapsed: 0,
                  }),
                },
              },
            },
            called: {},
            answered: {},
            no_answer: {},
            busy: {},
          },
        },
        completed: {},
        failure: {
          type: "final",
        },
      },
    },
    {
      actions: {
        createDevice: assign((context, event) => {}),
        setDevice: assign((context, event) => {}),
        saveErrorToContext: assign((context, event) => {
          return {
            errorType: event.errorType,
            error: event.error,
          };
        }),
        // setChannel,
      },
      guards: {
        deviceAndChannelReady,
      },
    }
  );

  return useMachine(machine, { devTools: true });
};

export default AutodialerMachine;
