import React from "react";

const NumberInputText = (props) => {
  return (
    <div className="input-group input-group-sm">
      <input
        type="tel"
        className="form-control"
        placeholder="555-666-7777"
        value={props.currentNumber}
        onChange={props.handleOnChange}
      />
    </div>
  );
};

export default NumberInputText;
